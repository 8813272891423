import React, { FC, PropsWithChildren, createContext, useContext, useState } from 'react';

import { type Subscription } from 'state/subscription/subscription-initial-state';
import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import { type User } from 'state/user/user-initial-state';

import useAiOfferPlansConfig from '../hooks/use-ai-offer-plans-config';

type PlansPeriod = 1 | 12;

interface AiOfferContextValue {
  plansConfig: ReturnType<typeof useAiOfferPlansConfig>;
  selectedPlansPeriod: PlansPeriod;
  shouldShowMonthlyPlans: boolean;
  toggleSelectedPlansPeriod: () => void;
  hasCountdownEnded: boolean;
  turnOffCountdown: () => void;
}

const initialValue: Partial<AiOfferContextValue> = {
  plansConfig: [],
  selectedPlansPeriod: 12,
  shouldShowMonthlyPlans: true,
  toggleSelectedPlansPeriod: () => {},
  hasCountdownEnded: false,
  turnOffCountdown: () => {},
};

const AiOfferContext = createContext<AiOfferContextValue>(initialValue as AiOfferContextValue);

interface ProviderProps {
  availablePricePlans: PricePlan[];
  primarySubscription: Subscription | undefined;
  user: User;
}

export const AiOfferProvider: FC<PropsWithChildren<ProviderProps>> = ({
  children,
  user,
  availablePricePlans,
  primarySubscription,
}) => {
  const [selectedPlansPeriod, setSelectedPlansPeriod] = useState<PlansPeriod>(12);
  const [hasCountdownEnded, sethasCountdownEnded] = useState<boolean>(false);

  const toggleSelectedPlansPeriod = () => {
    setSelectedPlansPeriod(selectedPlansPeriod === 1 ? 12 : 1);
  };

  const turnOffCountdown = () => {
    sethasCountdownEnded(true);
  };

  const shouldShowMonthlyPlans = primarySubscription?.pricePlan.paidMonth === 1;

  const plansConfig = useAiOfferPlansConfig({
    user,
    primarySubscription,
    selectedPlansPeriod,
    shouldShowMonthlyPlans,
    pricePlans: availablePricePlans,
  });

  const contextValue = {
    plansConfig,
    selectedPlansPeriod,
    shouldShowMonthlyPlans,
    toggleSelectedPlansPeriod,
    hasCountdownEnded,
    turnOffCountdown,
  };

  return (
    <AiOfferContext.Provider value={contextValue}>
      {children}
    </AiOfferContext.Provider>
  );
};

export const useAiOfferContext = () => {
  const context = useContext(AiOfferContext);

  if (context === undefined) {
    throw new Error('useAiOfferContext must be used within a AiOfferProvider');
  }

  return context;
};
