import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';

import { isEduPricePlan, isIosAdvancedPricePlan } from 'services/price-plans/utils';

import {
  isAppLandingFlow as isAppLandingFlowSelector,
  isIosOnlyUser as isIosOnlyUserSelector,
  isPaymentMethodCreated as isPaymentMethodCreatedSelector,
  isPaymentMethodFetched as isPaymentMethodFetchedSelector,
  isSubscriptionsFetched as isSubscriptionsFetchedSelector,
  isUpsaleFlow,
  isUsersEmailConfirmed as isUsersEmailConfirmedSelector,
  getDisplayedPricePlan,
  getFeaturedPricePlans,
  getPrimarySubscription,
  getTrialLength,
  getUser,
} from 'state/root-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import {
  MAC_PLUS_IOS_MONTHLY,
  IOS_MONTHLY,
  IOS_ADVANCED_MONTHLY
} from 'state/price-plans/price-plans-types';
import { fetchAllSubscriptions, changeTrialPricePlan, startTrial } from 'state/subscription/subscription-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import useCancellablePromise from 'components/hooks/use-cancelable-promise/use-cancelable-promise';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import DefaultError from 'components/shared/default-error/default-error';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import { SHOULD_SHOW_POPUP_QUERY } from 'components/pages/ios-apps/popup-about-download/popup-about-download';
import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';
import { CustomerOauthErrors } from 'components/pages/customer-oauth-page/utils/customer-oauth-errors';
import { getIsInsideSetappMobileFlow } from 'components/pages/setapp-mobile/utils/setapp-mobile-flow-storage';
import { SETAPP_MOBILE_TRIAL_NOTIF_QUERY } from 'components/pages/setapp-mobile/partials/onboarding-screen/onboarding-screen';

import useTrialAvailabilityStatusGuard from './hooks/use-trial-availability-status-guard/use-trial-availability-status-guard';

import SignupPaymentDetailsForm from './signup-payment-details-form/signup-payment-details-form';
import SignupPaymentDetailsDescription from './signup-payment-details-description/signup-payment-details-description';
import SignupPaymentDetailsPlans from './signup-payment-details-plans/signup-payment-details-plans';

import './signup-ccr-flow.scss';

type Step = 'details' | 'form' | 'plans';

const customerOauthErrorsToForceIosAdvancedPlan = [
  CustomerOauthErrors.EMPTY_PAYMENT_DETAILS,
  CustomerOauthErrors.APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION,
];

const SignupCcrFlow: FC = () => {
  const history = useHistory();

  const [successfulRegistrationPageLink, setSuccessfulRegistrationPageLink] = useState(urls.successfulRegistration);
  const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan>();
  const [activeStep, setActiveStep] = useState<Step>('details');

  const isAppLandingFlow = useSelector(isAppLandingFlowSelector);
  const user = useSelector(getUser);
  const isIosOnlyUser = useSelector(isIosOnlyUserSelector);
  const isPaymentMethodCreated = useSelector(isPaymentMethodCreatedSelector);
  const isPaymentMethodFetched = useSelector(isPaymentMethodFetchedSelector);
  const isSubscriptionsFetched = useSelector(isSubscriptionsFetchedSelector);
  const isUpSaleFlow = useSelector(isUpsaleFlow);
  const isUsersEmailConfirmed = useSelector(isUsersEmailConfirmedSelector);
  const featuredPricePlans = useSelector(getFeaturedPricePlans);
  const mainSubscription = useSelector(getPrimarySubscription);
  const pricePlan = useSelector(getDisplayedPricePlan);
  const trialLength = useSelector(getTrialLength);

  const changeTrialPricePlanAction = useDispatchAction(changeTrialPricePlan);
  const fetchAllSubscriptionsAction = useDispatchAction(fetchAllSubscriptions);
  const fetchPaymentMethodAction = useDispatchAction(fetchPaymentMethod);
  const fetchPricePlansAction = useDispatchAction(fetchPricePlans);
  const showDangerNotificationAction = useDispatchAction(showDangerNotification);
  const startTrialAction = useDispatchAction(startTrial);

  const isDesktopCcRequired = user.desktopCcRequired;
  const isLoading = !mainSubscription || !pricePlan || !featuredPricePlans.length;

  const storedCustomerOauthError = getStoredCustomerOauthError();
  const isCustomerOauthErrorFlow = Boolean(
    storedCustomerOauthError
    && customerOauthErrorsToForceIosAdvancedPlan.includes(storedCustomerOauthError)
  );
  const isFromSetappMobile = getIsInsideSetappMobileFlow();
  const shouldSetIosAdvancedPlan = isFromSetappMobile || isCustomerOauthErrorFlow;

  const eduUserConfirmEmailGuard = () => {
    const isEduUser = mainSubscription && isEduPricePlan(mainSubscription.pricePlan);
    const shouldRedirectToConfirmEmailPage = isEduUser && !isUsersEmailConfirmed;

    if (shouldRedirectToConfirmEmailPage) {
      history.replace({
        pathname: urls.signupConfirmEmail,
        search: history.location.search,
        state: { nextPathname: history.location.pathname },
      });
    }
  };

  const setNextStepForIosOnlyUser = () => {
    if (isIosOnlyUser && isMobile && !shouldSetIosAdvancedPlan) {
      setActiveStep('plans');
    }
  };

  const setSuccessfulRegistrationLink = () => {
    let successfulRegistrationPageLink: string | null = null;

    if (isCustomerOauthErrorFlow) {
      successfulRegistrationPageLink = urls.customerOauth;
    } else if (isFromSetappMobile) {
      const setappMobileUrl = `${urls.setappMobile}?${SETAPP_MOBILE_TRIAL_NOTIF_QUERY}=true`;

      successfulRegistrationPageLink = setappMobileUrl;
    } else if (isIosOnlyUser) {
      successfulRegistrationPageLink = urls.iosApps;
    } else if (isDesktopCcRequired) {
      successfulRegistrationPageLink = urls.successfulTrialActivation;
    } else if (isAppLandingFlow) {
      successfulRegistrationPageLink = urls.successfulRegistrationSingleApp;
    }

    if (successfulRegistrationPageLink) {
      setSuccessfulRegistrationPageLink(successfulRegistrationPageLink);
    }
  };

  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    const dataToBeFetched = [
      ...(!isSubscriptionsFetched ? [fetchAllSubscriptionsAction] : []),
      ...(!isPaymentMethodFetched ? [fetchPaymentMethodAction] : []),
      ...(featuredPricePlans.length === 0 ? [fetchPricePlansAction] : []),
    ];

    Promise.all(dataToBeFetched.map((action) => {
      return cancellablePromise(action());
    }))
      .then(() => {
        eduUserConfirmEmailGuard();
        setNextStepForIosOnlyUser();
        setSuccessfulRegistrationLink();
      })
      .catch(() => {
        showDangerNotificationAction(<DefaultError />);
      });
  }, []);

  useEffect(() => {
    if (featuredPricePlans.length === 0 || !isSubscriptionsFetched || !shouldSetIosAdvancedPlan) return;

    setSelectedPricePlan(featuredPricePlans.find((plan) => plan.tierType === IOS_ADVANCED_MONTHLY));
  }, [featuredPricePlans]);

  useTrialAvailabilityStatusGuard({ shouldIgnore: shouldSetIosAdvancedPlan });

  const startTrialAndRedirectToSuccessPage = async () => {
    const isSelectedPricePlanIosAdvanced = selectedPricePlan && isIosAdvancedPricePlan(selectedPricePlan.tierType);

    // In Desktop CCR flow we need to start trial manually
    // before redirecting to the successful registration page
    if (isDesktopCcRequired && !isSelectedPricePlanIosAdvanced) {
      try {
        await startTrialAction();
      } catch {
        showDangerNotificationAction(<DefaultError />);
        history.push(urls.subscription);

        return;
      }
    }

    history.push(successfulRegistrationPageLink);
  };

  const handlePricePlanSwitcherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTierType = event.currentTarget.value;
    const selectedPricePlan = featuredPricePlans.find((plan) => plan.tierType === selectedTierType);

    if (!selectedPricePlan) {
      throw new Error('Can\'t find correct price plan');
    }

    setSelectedPricePlan(selectedPricePlan);
  };

  const handleNextClick = (selectedPricePlan: PricePlan) => {
    setSelectedPricePlan(selectedPricePlan);
    setActiveStep('details');
  };

  const handlePaymentDetailsAdded = async () => {
    analytics.trackEvent(events.PAYMENT_DETAILS_ADD, {
      eventLabel2: selectedPricePlan?.priceKey
    });

    await startTrialAndRedirectToSuccessPage();
  };

  const handleStartTrialClick = async () => {
    try {
      if (selectedPricePlan) {
        await changeTrialPricePlanAction(selectedPricePlan.priceKey);

        if (selectedPricePlan.tierType === MAC_PLUS_IOS_MONTHLY && isMobile) {
          setSuccessfulRegistrationPageLink(`${urls.iosApps}?${SHOULD_SHOW_POPUP_QUERY}`);
        }
        if (selectedPricePlan.tierType === IOS_MONTHLY && isMobile) {
          setSuccessfulRegistrationPageLink(urls.iosApps);
        }

        analytics.trackEvent(events.PAYMENT_DETAILS_CONFIRM_CHANGE_PLAN, {
          eventLabel: selectedPricePlan.priceKey,
          eventLabel2: pricePlan?.priceKey
        });
      }

      analytics.trackEvent(events.PAYMENT_DETAILS_START_TRIAL_CLICK);

      if (isPaymentMethodCreated) {
        await startTrialAndRedirectToSuccessPage();
      } else {
        setActiveStep('form');
      }
    } catch {
      showDangerNotificationAction(<DefaultError />);
    }
  };

  const handleFormBackClick = () => {
    setActiveStep('details');
  };

  const getStepClass = (page: Step) => {
    return activeStep === page ? '' : 'signup-ccr-flow__hidden-item';
  };

  if (isLoading) {
    return <FullscreenLayoutLoading />;
  }

  const displayedPricePlan = selectedPricePlan ?? pricePlan;

  return (
    <div className="signup-ccr-flow">
      <div className={getStepClass('details')}>
        <SignupPaymentDetailsDescription
          pricePlan={displayedPricePlan}
          featuredPricePlans={featuredPricePlans}
          trialLength={trialLength}
          isPricePlanSelected={Boolean(selectedPricePlan)}
          onStartTrialClick={handleStartTrialClick}
          onPricePlanSwitcherChange={handlePricePlanSwitcherChange}
        />
      </div>
      <div className={getStepClass('form')} data-testid="addPaymentDetailsForm">
        <SignupPaymentDetailsForm
          pricePlan={displayedPricePlan}
          onPaymentDetailsAdded={handlePaymentDetailsAdded}
          isUpsaleFlow={isUpSaleFlow}
          onBackButtonClick={handleFormBackClick}
        />
      </div>
      <div className={getStepClass('plans')}>
        <SignupPaymentDetailsPlans onNextClick={handleNextClick} />
      </div>
    </div>
  );
};

export default SignupCcrFlow;
