import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Notification } from '@setapp/ui-kit';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import DefaultError from 'components/shared/default-error/default-error';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';

import externalUrls from 'config/external-urls';

import analytics, { events } from 'utils/analytics';

import { clearNotifications } from 'state/notifier/notifier-reducer';
import useMobileInstallationUrl from '../../utils/get-mobile-installation-url';

import SetappMobileFaq from './setapp-mobile-faq';
import SetappMobileFixedBtn from './fixed-button';

import settingsImg1x from '../../images/settings.png';
import settingsImg2x from '../../images/settings@2x.png';
import settingsImg3x from '../../images/settings@3x.png';
import homescreenImg1x from '../../images/homescreen.png';
import homescreenImg2x from '../../images/homescreen@2x.png';
import homescreenImg3x from '../../images/homescreen@3x.png';
import installSheetImg1x from '../../images/install-sheet.png';
import installSheetImg2x from '../../images/install-sheet@2x.png';
import installSheetImg3x from '../../images/install-sheet@3x.png';

import './onboarding-screen.scss';

export const SETAPP_MOBILE_TRIAL_NOTIF_QUERY = 'showSetappMobileTrialNotif';
export const SETAPP_MOBILE_SUBSCRIPTION_NOTIF_QUERY = 'showSetappMobileSubscriptionNotif';

const SetappMobileOnboarding: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { mobileInstallationUrl, urlError } = useMobileInstallationUrl();
  const searchParams = new URLSearchParams(location.search);
  const shouldShowTrialNotif = searchParams.get(SETAPP_MOBILE_TRIAL_NOTIF_QUERY);
  const shouldShowSubscriptionNotif = searchParams.get(SETAPP_MOBILE_SUBSCRIPTION_NOTIF_QUERY);

  let setappMobileNotification: JSX.Element | undefined;

  useEffect(() => {
    if (shouldShowTrialNotif || shouldShowSubscriptionNotif) {
      dispatch(clearNotifications());
    }
  }, []);

  if (shouldShowTrialNotif) {
    setappMobileNotification = (
      <Notification type="success">
        <FormattedMessage
          id="mobileOnboard.trialNotification"
          defaultMessage="Your trial is ready! Now, follow our guide to install Setapp Mobile."
        />
      </Notification>
    );
  } else if (shouldShowSubscriptionNotif) {
    setappMobileNotification = (
      <Notification type="success">
        <FormattedMessage
          id="mobileOnboard.subscriptionNotification"
          defaultMessage="Your subscription is ready! Now, follow our guide to install Setapp Mobile."
        />
      </Notification>
    );
  }

  if (urlError) {
    return <DefaultError />;
  }

  if (!mobileInstallationUrl) {
    return <AppLayoutLoading />;
  }

  const handleInstallClick = () => {
    analytics.trackEvent(events.WELCOME_PAGE_DOWNLOAD_STARTED);
  };

  return (
    <div>
      {setappMobileNotification && (
        <div className="mb-7">
          {setappMobileNotification}
        </div>
      )}
      <h1 className="setapp-mobile-onboard__title">
        <FormattedMessage id="mobileOnboard.title" defaultMessage="Get Setapp Mobile on your iPhone" />
      </h1>

      <div className="setapp-mobile-onboard__item">
        <p className="setapp-mobile-onboard__item-title">
          <FormattedMessage id="mobileOnboard.step1.title" defaultMessage="1. Make sure that:" />
        </p>
        <ul className="setapp-mobile-onboard__feature-list">
          <li className="mb-2 setapp-mobile-onboard__list-item">
            <FormattedMessage id="mobileOnboard.euLocation" defaultMessage="You’re physically located in the EU" />
          </li>
          <li className="mb-2 setapp-mobile-onboard__list-item">
            <FormattedMessage id="mobileOnboard.iosVersion" defaultMessage="Your iPhone runs iOS 17.4 or later" />
          </li>
          <li className="mb-2 setapp-mobile-onboard__list-item">
            <FormattedMessage
              id="mobileOnboard.appleIdCountry"
              defaultMessage="Your Apple ID country is one of the EU states"
            />
          </li>
          <li className="mb-2 setapp-mobile-onboard__list-item">
            <FormattedMessage
              id="mobileOnboard.safariBrowser"
              defaultMessage="You use Safari (download may not work in other browsers yet)"
            />
          </li>
        </ul>
      </div>

      <div className="setapp-mobile-onboard__item">
        <p className="setapp-mobile-onboard__item-title">
          <FormattedMessage id="mobileOnboard.step2.title" defaultMessage="2. Tap Install Setapp Mobile" />
        </p>
      </div>

      <div className="setapp-mobile-onboard__item">
        <p className="setapp-mobile-onboard__item-title">
          <FormattedMessage id="mobileOnboard.step3.title" defaultMessage="3. Allow Setapp Mobile in Settings" />
        </p>
        <p className="setapp-mobile-onboard__item-description">
          <FormattedHTMLMessage
            id="mobileOnboard.step3.description"
            defaultMessage='Installing it for the first time? Open the <b>Settings</b> app on your iPhone and tap <b>Allow Marketplace From "MacPaw Way Ltd" </b> at the top. Then, tap Allow.'
          />
        </p>
        <img
          className="setapp-mobile-onboard__image"
          src={settingsImg1x}
          srcSet={`${settingsImg2x} 2x, ${settingsImg3x} 3x`}
          alt=""
        />
      </div>

      <div className="setapp-mobile-onboard__item">
        <p className="setapp-mobile-onboard__item-title">
          <FormattedMessage id="mobileOnboard.step4.title" defaultMessage="4. Return to this webpage and tap Install Setapp Mobile again." />
        </p>
        <Button block size="lg" className="mt-5" href={mobileInstallationUrl} onClick={handleInstallClick}>
          <FormattedMessage id="mobileOnboard.btn.get" defaultMessage="Install Setapp Mobile" />
        </Button>
      </div>

      <div className="setapp-mobile-onboard__item">
        <p className="setapp-mobile-onboard__item-title">
          <FormattedMessage id="mobileOnboard.step5.title" defaultMessage="5. Tap Install App Marketplace on the iOS install sheet." />
        </p>

        <img
          className="setapp-mobile-onboard__image"
          src={installSheetImg1x}
          srcSet={`${installSheetImg2x} 2x, ${installSheetImg3x} 3x`}
          alt=""
        />
      </div>

      <div className="setapp-mobile-onboard__item">
        <p className="setapp-mobile-onboard__item-title">
          <FormattedMessage id="mobileOnboard.step6.title" defaultMessage="6. Ready! Setapp Mobile is now on your Home Screen." />
        </p>

        <img
          className="setapp-mobile-onboard__image"
          src={homescreenImg1x}
          srcSet={`${homescreenImg2x} 2x, ${homescreenImg3x} 3x`}
          alt=""
        />
      </div>

      <p className="setapp-mobile-onboard__faq-title">
        <FormattedMessage id="mobileOnboard.faqTitle" defaultMessage="Need any help?" />
      </p>
      <SetappMobileFaq />

      <Button variant="link" href={externalUrls.setappMobilePlans} className="setapp-mobile-onboard__knowledge">
        <FormattedMessage id="mobileOnboard.knowledge" defaultMessage="Check our knowledge base" />
      </Button>

      <SetappMobileFixedBtn mobileInstallationUrl={mobileInstallationUrl} handleInstallClick={handleInstallClick} />
    </div>
  );
};

export default SetappMobileOnboarding;
