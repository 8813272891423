import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import DefaultError from 'components/shared/default-error/default-error';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import {
  getAvailablePricePlans,
  getUserId,
  getPrimarySubscription,
  isSubscriptionNew as getIsSubscriptionNew,
  isSubscriptionFree as getIsSubscriptionFree,
  isUserFamilyMember as getIsUserFamilyMember,
  getFeatureFlags,
  getUser
} from 'state/root-reducer';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { fetchUser } from 'state/user/user-actions';
import { isAiEnthusiastPricePlan, isAiExpertPricePlan } from 'services/price-plans/utils';
import MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER from 'services/manage-subscription/manage-subscription-preselected-step-mapper';

import urls from 'config/urls';

import NotAllowed from './components/not-allowed/not-allowed';
import { AiOfferProvider } from './context/ai-offer-context';
import AiOfferView from './ai-offer-view';

const AiOfferPage = () => {
  const history = useHistory();
  const isUserFetched = Boolean(useSelector(getUserId));
  const user = useSelector(getUser);
  const primarySubscription = useSelector(getPrimarySubscription);
  const availablePricePlans = useSelector(getAvailablePricePlans);
  const arePricePlansFetched = Boolean(availablePricePlans.length);
  const isSubscriptionNew = useSelector(getIsSubscriptionNew);
  const isSubscriptionFree = useSelector(getIsSubscriptionFree);
  const isUserFamilyMember = useSelector(getIsUserFamilyMember);
  const { enableAiOfferPlans } = useSelector(getFeatureFlags);

  const isEnthusiastPlan = primarySubscription?.pricePlan?.tierType
    && isAiEnthusiastPricePlan(primarySubscription?.pricePlan?.tierType);
  const isExpertPlan = primarySubscription?.pricePlan?.tierType
    && isAiExpertPricePlan(primarySubscription?.pricePlan?.tierType);

  const fetchUserAction = useDispatchAction(fetchUser);
  const fetchPricePlansAction = useDispatchAction(fetchPricePlans);
  const fetchSubscriptionsAction = useDispatchAction(fetchAllSubscriptions);
  const showDangerNotificationAction = useDispatchAction(showDangerNotification);

  useEffect(() => {
    const dataToBeFetched = [
      !isUserFetched ? fetchUserAction : null,
      !arePricePlansFetched ? fetchPricePlansAction : null,
      !primarySubscription ? fetchSubscriptionsAction : null,
    ];

    Promise.all(dataToBeFetched.map((action) => action?.())).catch(() => {
      showDangerNotificationAction(<DefaultError />);
    });

    if (isEnthusiastPlan) {
      history.push(`${urls.subscription}?show_change_plan_modal=${MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER.ManagePlan}`);
    }

    if (isExpertPlan) {
      history.push(urls.subscription);
    }
  }, []);

  if (isUserFamilyMember) return <NotAllowed type="family" />;
  if (isSubscriptionNew) return <NotAllowed type="new" />;
  if (isSubscriptionFree) return <NotAllowed type="free" />;

  if (!enableAiOfferPlans.value) {
    return <Redirect to={urls.subscription} />;
  }

  return (
    <AiOfferProvider
      availablePricePlans={availablePricePlans}
      primarySubscription={primarySubscription}
      user={user}
    >
      <AiOfferView />
    </AiOfferProvider>
  );
};

export default AiOfferPage;
