import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { osVersion, isIOS } from 'react-device-detect';

import { apiURL } from 'config/api';

import request from 'utils/request';

import { getFeatureFlags, getPrimaryPricePlan, isFreeUser as isFreeUserSelector } from 'state/root-reducer';

import useGetPlanForUpgrade from 'components/hooks/use-upgrade-plan-setapp-mobile/use-upgrade-plan-setapp-mobile';

import { isIosAdvancedPricePlan } from 'services/price-plans/utils';

import checkIfSuitableVersion from './check-version';
import { SetappMobileProblemsType, SetappMobileProblemCodes } from './setapp-mobile-problem-codes';
import { isAlreadyVisitedSetappMobile, setAlreadyVisitedSetappMobile } from './is-already-visited-setapp-mobile';
import { getIsInsideSetappMobileFlow, removeIsInsideSetappMobileFlow } from './setapp-mobile-flow-storage';

type CheckMobileAccessResult = SetappMobileProblemsType | 'ACCESS_GRANTED' | undefined;

let failedAttempts = 0;

const useCheckSetappMobileAccess = (): CheckMobileAccessResult => {
  const [result, setResult] = useState<CheckMobileAccessResult>(undefined);
  const isFreeUser = useSelector(isFreeUserSelector);
  const pricePlan = useSelector(getPrimaryPricePlan);
  const { planForUpgrade, isUpgradeSearchFinished } = useGetPlanForUpgrade();
  const { iosEuBetaTestEnabled } = useSelector(getFeatureFlags);

  // closed beta is in progress, so we need to check if it is disabled
  const isNewSetappMobileFlowEnabled = iosEuBetaTestEnabled.value === 0;

  const closedBetaChecker = () => {
    if (getIsInsideSetappMobileFlow()) {
      removeIsInsideSetappMobileFlow();
      setAlreadyVisitedSetappMobile();

      return 'ACCESS_GRANTED';
    }

    if (!isAlreadyVisitedSetappMobile()) {
      setAlreadyVisitedSetappMobile();
      if (pricePlan && (isIosAdvancedPricePlan(pricePlan?.tierType) || isFreeUser)) {
        return 'ACCESS_GRANTED';
      }

      return 'FIRST_TIME_VISIT';
    }

    return 'ACCESS_GRANTED';
  };

  const openBetaChecker = () => {
    if (planForUpgrade) {
      return 'OFFER_UPGRADE';
    }

    removeIsInsideSetappMobileFlow();

    return 'ACCESS_GRANTED';
  };

  const getCheckAccessResult = async (): Promise<CheckMobileAccessResult> => {
    try {
      if (!isIOS) {
        return 'DEVICE_ERROR';
      }

      if (!checkIfSuitableVersion(osVersion)) {
        return 'OUTDATED_IOS_VERSION';
      }

      const data = await request.get(apiURL.mobileStoreAccessInfo);

      if (data.hasAccess) {
        return isNewSetappMobileFlowEnabled ? openBetaChecker() : closedBetaChecker();
      }

      return 'UNKNOWN_ERROR';
    } catch (error: any) {
      const errorMessage = error.errors?.[0]?.message as SetappMobileProblemsType;

      if (Object.keys(SetappMobileProblemCodes).includes(errorMessage)) {
        if (errorMessage === 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION' && isNewSetappMobileFlowEnabled) {
          if (planForUpgrade && isUpgradeSearchFinished) {
            return 'OFFER_UPGRADE';
          }

          return 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION';
        }

        if (errorMessage === 'EMPTY_PAYMENT_DETAILS' && getIsInsideSetappMobileFlow()) {
          if (failedAttempts >= 2) {
            return 'UNKNOWN_ERROR';
          }

          await new Promise((resolve) => setTimeout(resolve, 2000));

          failedAttempts += 1;

          return getCheckAccessResult();
        }

        return errorMessage;
      } else {
        return 'UNKNOWN_ERROR';
      }
    }
  };

  useEffect(() => {
    if (!isUpgradeSearchFinished) return;

    getCheckAccessResult().then((result) => setResult(result));
  }, [isUpgradeSearchFinished, planForUpgrade]);

  return result;
};

export default useCheckSetappMobileAccess;
