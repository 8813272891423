import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAiOfferContext } from '../../context/ai-offer-context';

import ClockIcon from './images/clock-icon.svg';
import SparklesIcon from './images/sparkles-icon.svg';
import PrivacyIcon from './images/privacy-icon.svg';

import './ai-offer-benefits.scss';

const benefitsConfig = [
  {
    icon: ClockIcon,
    alt: 'Access benefit',
    text: (
      <FormattedMessage
        id="aiOffer.benefits.access"
        defaultMessage="Access to the latest text, image, and audio GenAI models."
      />
    ),
  }, {
    icon: SparklesIcon,
    alt: 'AI apps benefit',
    text: (
      <FormattedMessage
        id="aiOffer.benefits.aiApps"
        defaultMessage="AI apps for coding, content and art generation, design, and more."
      />
    ),
  }, {
    icon: PrivacyIcon,
    alt: 'Privacy benefit',
    text: (
      <FormattedMessage
        id="aiOffer.benefits.privacy"
        defaultMessage="Privacy. We won’t use your data for AI model training."
      />
    ),
  },
];

const AiOfferBenefits = () => {
  const { hasCountdownEnded } = useAiOfferContext();

  const benefitsClasses = classNames('offer-benefits', {
    'offer-benefits_with-border': hasCountdownEnded,
  });

  return (
    <ul className={benefitsClasses}>
      {benefitsConfig.map(({ icon, text, alt }) => (
        <li key={icon} className="offer-benefits__item">
          <img src={icon} alt={alt} />
          <p className="offer-benefits__text">
            {text}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default AiOfferBenefits;
