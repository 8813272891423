import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { ButtonLoading, FormSwitcher } from '@setapp/ui-kit';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';

import PRICE_PLAN_PERIOD_MAPPER from 'services/price-plans/price-plan-period-mapper';
import type { SubscriptionActivationOrder } from 'services/subscriptions/subscriptions-api';

import * as subscriptionStatuses from 'state/subscription/statuses';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { ActivateSubscriptionAction, ChangePricePlanAction } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import { IOS_ADVANCED_MONTHLY } from 'state/price-plans/price-plans-types';
import { User } from 'state/user/user-initial-state';

import PageTitle from 'components/shared/page-title/page-title';
import PaymentForm from 'components/shared/payment-form/payment-form';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import { FormattedPrice } from 'components/shared/formatter/formatter';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import InfoButton from 'components/shared/info-button/info-button';
import ButtonBack from 'components/shared/button-back/button-back';
import PaymentFormTotal from 'components/shared/payment-form-total/payment-form-total';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import SubscriptionActivated from 'components/shared/subscription-activated/subscription-activated';
import TaxInfo from 'components/shared/tax-info/tax-info';
import withPaymentWaiting from 'components/shared/with-payment-waiting/with-payment-waiting';
import { getIsInsideSetappMobileFlow } from 'components/pages/setapp-mobile/utils/setapp-mobile-flow-storage';

import ActivateSubscriptionPromo from './activate-subscription-promo/activate-subscription-promo';
import ActivationExtraDevicesSelect from './activation-extra-devices-select/activation-extra-devices-select';
import ChoosePlanScreen from './choose-plan-screen/choose-plan-screen';
import RetrySubscriptionActivation from './retry-subscription-activation/retry-subscription-activation';
import SelectPricePlan from './select-price-plan/select-price-plan';

import './activate-new-user-flow.scss';


type Props = {
  hasPriceFeatures: boolean;
  isPaymentMethodCreated: boolean;
  isReferralUser: boolean;
  isSubscriptionProcessing: boolean;
  activeDevicesCount: number;
  activeMacDevicesCount: number;
  appsCount: number;
  additionalSeatPricePlan: PricePlan | undefined;
  primarySubscription: Subscription;
  pricePlans: Array<PricePlan>;
  featuredPricePlans: Array<PricePlan>;
  activateSubscription: ActivateSubscriptionAction;
  changePricePlan: ChangePricePlanAction;
  user: User;
};

type State = {
  showTryAgainStep: boolean;
  selectedPricePlan: PricePlan | null;
  selectedExtraDevicesCount: number | null;
  pricePlanCardSelected: boolean;
  switchedToAnnual: boolean;
};

// FIXME: This flow is too complex. It currently used to activate new user with old price plan,
//  new user with new price plan and trial user
class ActivateNewUserFlow extends Component<Props, State> {
  state: State = {
    showTryAgainStep: false,
    selectedPricePlan: null,
    selectedExtraDevicesCount: null,
    pricePlanCardSelected: false,
    switchedToAnnual: false,
  };

  isInSetappMobileFlow = false;

  componentDidMount() {
    const { primarySubscription, featuredPricePlans } = this.props;

    const isFromSetappMobile = getIsInsideSetappMobileFlow();
    const iosAdvancedPlan = featuredPricePlans.find((plan) => plan.tierType === IOS_ADVANCED_MONTHLY);

    if (iosAdvancedPlan && isFromSetappMobile) {
      this.isInSetappMobileFlow = true;
      this.setState({ selectedPricePlan: iosAdvancedPlan });
      this.setState({ pricePlanCardSelected: true });
    }

    if (primarySubscription && primarySubscription.nextPricePlan
      && primarySubscription.nextPricePlan.paidMonth === 12) {
      this.setState({ switchedToAnnual: true });
    }
  }

  render() {
    const {
      hasPriceFeatures,
      isPaymentMethodCreated,
      isReferralUser,
      isSubscriptionProcessing,
      activeMacDevicesCount,
      appsCount,
      additionalSeatPricePlan,
      primarySubscription,
      pricePlans,
      featuredPricePlans,
      user,
    } = this.props;

    const {
      showTryAgainStep,
      pricePlanCardSelected,
      switchedToAnnual,
      selectedPricePlan,
    } = this.state;

    const isTrialUser = primarySubscription.status === subscriptionStatuses.TRIAL;

    const { accountCurrency, isSetappMobileAvailable } = user;

    // Show success screen for already activated users
    // WARNING: This logic placed here and not in the `ActivateSubscriptionPage` component to
    // not break the `withPaymentWaiting` HOC
    const isSubscriptionActivated = primarySubscription.status === subscriptionStatuses.ACTIVE
      && !primarySubscription.paymentPending;
    if (isSubscriptionActivated) {
      return <SubscriptionActivated />;
    }

    // Show success screen for trial user
    const isTrialUserSelectedPlan = isTrialUser && isPaymentMethodCreated && pricePlanCardSelected;
    if (isTrialUserSelectedPlan) {
      return <SubscriptionActivated isTrialUser={isTrialUser} />;
    }

    const isNewUser = primarySubscription.status === subscriptionStatuses.NEW;
    // Cancelled user is not that "new", but still if user cancels, we should show allow him to activate
    const isCancelledUser = primarySubscription.status === subscriptionStatuses.CANCELLED;

    if (!isNewUser && !isTrialUser && !isCancelledUser && !primarySubscription.paymentPending) {
      return <Redirect to={urls.subscription} />;
    }

    if (hasPriceFeatures && !pricePlanCardSelected && featuredPricePlans.length) {
      return (
        <ChoosePlanScreen
          user={user}
          isNewUser={isNewUser}
          isReferralUser={isReferralUser}
          switchedToAnnual={switchedToAnnual}
          activeMacDevicesCount={activeMacDevicesCount}
          onChangePlanPeriod={this.handleChangePlanPeriod}
          onSelectPlanClick={this.handleSelectPriceCard}
        />
      );
    }

    const currentPricePlan = this.getPrimaryPricePlanToActivate();
    const activeExtraDevicesCount = this.getActiveExtraDevicesCount();
    const extraDevicesCountToOrder = this.getExtraDevicesCountToOrder();
    const showFeaturedPricePlanActivation = hasPriceFeatures && pricePlanCardSelected && Boolean(selectedPricePlan);

    let totalAmount = currentPricePlan.price;
    if (additionalSeatPricePlan) {
      totalAmount += additionalSeatPricePlan.price * extraDevicesCountToOrder;
    }

    const renderTaxInfo = () => {
      if (accountCurrency === 'EUR') {
        return (
          <FormattedHTMLMessage
            id="activatePage.vatInfo"
            defaultMessage="This is an estimated price for your location.<br/>The final amount may vary depending on applicable VAT."
          />
        );
      }
      if (accountCurrency === 'USD' && isSetappMobileAvailable) {
        return (
          <FormattedHTMLMessage
            id="activatePage.vatUsdInfo"
            defaultMessage="Tax may apply depending on your country.<br/>Check your bank statement for details."
          />
        );
      }

      return <TaxInfo />;
    };

    return (
      <FullscreenLayout withLogo={showFeaturedPricePlanActivation}>
        <FullscreenLayout.PrimaryContent wideContainer={showFeaturedPricePlanActivation}>
          <div className="activate-new-user-flow">
            {!showFeaturedPricePlanActivation && (
              <div className="activate-new-user-flow__logo">
                <AnimatedLogo hasCaption size="sm" />
              </div>
            )}
            <div className="activate-new-user-flow__content" data-qa="activatePageContent">
              {showTryAgainStep ? (
                <RetrySubscriptionActivation
                  onRetryClick={this.activateSubscription}
                  onBackClick={this.hideTryAgainStep}
                  isLoading={isSubscriptionProcessing}
                />
              ) : (
                <div>
                  <div className="mb-5">
                    <ButtonBack
                      onClick={this.handleBackClick}
                      visible={pricePlanCardSelected && !this.isInSetappMobileFlow}
                    />
                  </div>

                  {showFeaturedPricePlanActivation ? (
                    <>
                      <PageTitle>
                        {
                          isTrialUser
                            ? <FormattedMessage id="activatePage.titleFeaturedForTrial" defaultMessage="Let’s set up your subscription" />
                            : <FormattedMessage id="activatePage.titleFeatured" defaultMessage="Let’s start your subscription" />
                        }
                      </PageTitle>
                      {isTrialUser && (
                        <p className="text_lg mb-8">
                          We will keep your payment information on file and will charge for your subscription only after
                          your trial ends.
                        </p>
                      )}
                      {selectedPricePlan && (
                        <div className="text_lg text_weight-bold mt-6">
                          <PricePlanTitle tierType={selectedPricePlan.tierType} />
                        </div>
                      )}
                      {!this.isInSetappMobileFlow && (
                        <>
                        <hr className="activate-new-user-flow__delimiter" />
                          <FormSwitcher
                            className="mb-5"
                            id="annual-plan-switcher"
                            onChange={this.handleSwitchPlan}
                            checked={switchedToAnnual}
                          >
                            <FormattedMessage id="activatePage.switchToAnnual" defaultMessage="Save 10% annually" />
                          </FormSwitcher>
                        </>
                      )}
                      {isReferralUser && this.renderFreeMonthHint()}
                    </>
                  ) : (
                    <>
                      <PageTitle>
                        <FormattedMessage id="activatePage.title" defaultMessage="Get your Setapp membership" />
                      </PageTitle>

                      <div className="activate-new-user-flow__plan-selector">
                        <SelectPricePlan
                          pricePlans={pricePlans}
                          currentPricePlan={currentPricePlan}
                          onChange={this.handlePricePlanChange}
                        />
                      </div>

                      {isReferralUser && this.renderFreeMonthHint()}

                      <hr className="activate-new-user-flow__delimiter" />

                      {additionalSeatPricePlan && (
                        <div className="activate-new-user-flow__select-devices">
                          <ActivationExtraDevicesSelect
                            maxExtraDevicesCount={currentPricePlan.maxSeatsCount - currentPricePlan.seatsCount}
                            selectedExtraDevicesCount={extraDevicesCountToOrder}
                            activeExtraDevicesCount={activeExtraDevicesCount}
                            pricePlan={additionalSeatPricePlan}
                            onChange={this.handleDevicesCountChange}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className="activate-new-user-flow__total-amount">
                    <PaymentFormTotal
                      totalPrice={<FormattedPrice currency={currentPricePlan.currency} price={totalAmount} />}
                      isTrialUser={isTrialUser}
                    />
                  </div>

                  {!isPaymentMethodCreated && (
                    isTrialUser ? (
                      <PaymentForm
                        onSuccessSubmit={this.changePricePlan}
                        braintreeFormOptions={{
                          submitBtnTitle: <FormattedMessage id="activatePage.ctaForTrial" defaultMessage="Save Card" />,
                        }}
                      />
                    ) : (
                      <PaymentForm
                        onSuccessSubmit={this.activateSubscription}
                        paymentMethodOptions={{
                          amount: totalAmount,
                          currencyCode: currentPricePlan.currency,
                        }}
                        braintreeFormOptions={{
                          submitBtnTitle: <FormattedMessage id="activatePage.cta" defaultMessage="Confirm and pay" />,
                          loadingText: (
                            <FormattedMessage
                              id="activatePage.formLoadingText"
                              defaultMessage="Payment in progress…"
                            />
                          ),
                        }}
                      />
                    )
                  )}

                  {isPaymentMethodCreated && (
                    <ButtonLoading
                      disabled={isSubscriptionProcessing || primarySubscription.paymentPending}
                      isLoading={isSubscriptionProcessing || primarySubscription.paymentPending}
                      loadingText={
                        <FormattedMessage
                          id="activatePage.waitingForPaymentText"
                          defaultMessage="Payment in progress…"
                        />
                      }
                      block
                      onClick={this.activateSubscription}
                      className="mt-6"
                      data-qa="confirmButton"
                    >
                      <FormattedMessage
                        id="activatePage.confirmButton"
                        defaultMessage="Confirm and pay"
                      />
                    </ButtonLoading>
                  )}

                  <div className="mb-2 mt-5">
                    <NextPaymentDateEstimation
                      price={totalAmount}
                      currency={currentPricePlan.currency}
                      paidMonth={currentPricePlan.paidMonth}
                    />
                  </div>

                  {renderTaxInfo()}
                </div>
              )}
            </div>
          </div>
        </FullscreenLayout.PrimaryContent>
        {!showFeaturedPricePlanActivation && (
          <FullscreenLayout.SecondaryContent>
            <ActivateSubscriptionPromo appsCount={appsCount} />
          </FullscreenLayout.SecondaryContent>
        )}
      </FullscreenLayout>
    );
  }

  renderFreeMonthHint() {
    return (
      <p className="mt-3 mb-5" data-qa="freeMonthField">
        <FormattedMessage
          id="activatePage.freeMonthHint"
          defaultMessage="+ 1 free month"
        />
        &nbsp;
        <InfoButton>
          <FormattedMessage
            id="activatePage.freeMonthTooltip"
            defaultMessage="That’s a reward for starting with Setapp as a part of our referral program"
          />
        </InfoButton>
      </p>
    );
  }

  activateSubscription = () => {
    const { activateSubscription, additionalSeatPricePlan, hasPriceFeatures } = this.props;

    let activateSubscriptionOrder: SubscriptionActivationOrder = {
      primaryPricePlanId: this.getPrimaryPricePlanToActivate().id,
    };
    const extraDevicesCountToOrder = this.getExtraDevicesCountToOrder();

    if (!hasPriceFeatures && extraDevicesCountToOrder) {
      if (!additionalSeatPricePlan) {
        throw new Error('Can\'t find additional seats price plan');
      }

      activateSubscriptionOrder = {
        ...activateSubscriptionOrder,
        seats: {
          pricePlanId: additionalSeatPricePlan.id,
          count: extraDevicesCountToOrder,
        },
      };
    }

    return activateSubscription(activateSubscriptionOrder)
      .catch(() => {
        this.setState({ showTryAgainStep: true });
      });
  };

  changePricePlan = () => {
    const { changePricePlan, primarySubscription } = this.props;
    const { selectedPricePlan } = this.state;

    if (!selectedPricePlan) {
      throw new Error('Can\'t find price plan with the selected id');
    }

    return changePricePlan(primarySubscription.id, selectedPricePlan.id);
  }

  hideTryAgainStep = () => {
    this.setState({ showTryAgainStep: false });
  };

  handlePricePlanChange = (e) => {
    const { pricePlans } = this.props;
    const selectedId = Number(e.currentTarget.value);
    const selectedPlan = pricePlans.find((plan) => plan.id === selectedId);

    if (!selectedPlan) {
      throw new Error('Can\'t find price plan with the selected id');
    }

    analytics.trackEvent(events.SUBSCRIPTION_ACTIVATION_CHANGE_PRICE_PLAN, { eventLabel: selectedPlan.tierType });

    const newMaxExtraDevicesCount = selectedPlan.maxSeatsCount - selectedPlan.seatsCount;

    this.setState((state) => ({
      selectedPricePlan: selectedPlan,
      /**
       * Handle case when the selected price plan has more seats than the previous one
       * and the selected extra devices amount might be greater than the new max extra devices amount
       */
      selectedExtraDevicesCount: Math.min(Number(state.selectedExtraDevicesCount), newMaxExtraDevicesCount),
    }));
  };

  handleDevicesCountChange = (e) => {
    const newDevicesCount = Number(e.currentTarget.value);

    analytics.trackEvent(events.SUBSCRIPTION_ACTIVATION_CHANGE_DEVICES_COUNT, { eventValue: newDevicesCount });
    this.setState({ selectedExtraDevicesCount: newDevicesCount });
  }

  getPrimaryPricePlanToActivate() {
    const { primarySubscription } = this.props;
    const { selectedPricePlan } = this.state;

    if (selectedPricePlan) {
      return selectedPricePlan;
    }

    return primarySubscription.nextPricePlan || primarySubscription.pricePlan;
  }

  getActiveExtraDevicesCount() {
    const { activeDevicesCount } = this.props;

    return Math.max(activeDevicesCount - this.getPrimaryPricePlanToActivate().seatsCount, 0);
  }

  getExtraDevicesCountToOrder() {
    const { selectedExtraDevicesCount } = this.state;

    return selectedExtraDevicesCount ?? this.getActiveExtraDevicesCount();
  }

  handleSelectPriceCard = (selectedPlan: PricePlan) => {
    this.setState({
      selectedPricePlan: selectedPlan,
      pricePlanCardSelected: true,
    });
  }

  handleChangePlanPeriod = () => {
    const { switchedToAnnual } = this.state;

    this.setState({
      switchedToAnnual: !switchedToAnnual,
    });
  }

  handleBackClick = () => {
    this.setState({
      pricePlanCardSelected: false,
      selectedPricePlan: null,
    });
  }

  handleSwitchPlan = () => {
    const { featuredPricePlans } = this.props;
    const { selectedPricePlan, switchedToAnnual } = this.state;

    if (!selectedPricePlan || !featuredPricePlans) {
      throw new Error('Can\'t find selected price plan');
    }

    const newTierType = PRICE_PLAN_PERIOD_MAPPER[selectedPricePlan.tierType];
    const newSelectedPlan = featuredPricePlans.find(
      (plan) => plan.tierType === newTierType
    );

    if (!newSelectedPlan) {
      throw new Error('Can\'t find new price plan with correct tierType');
    }

    this.setState({
      selectedPricePlan: newSelectedPlan,
      switchedToAnnual: !switchedToAnnual,
    });
  }
}

export { ActivateNewUserFlow as PureActivateNewUserFlow };

export default withPaymentWaiting(ActivateNewUserFlow);
