import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import urls from 'config/external-urls';
import SupportLink from 'components/shared/support-link/support-link';
import FooterSocial from 'components/layout/footer/footer-social/footer-social';

import macpawWordmark from './images/macpaw-wordmark.svg';

import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer-content">
        <Row>
          <Col sm={8}>
            <span>{`© ${new Date().getFullYear()} MacPaw Way Ltd., 6 Maximou Michailidi Str, Maximos Plaza Tower 3, 4th floor, Flat/Office 401, 3106, Limassol, Cyprus`}</span>
            <div className="footer-content__terms">
              <a className="footer-content__link" href={urls.macpawSite} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="footer.creditsLink" defaultMessage="Crafted by" />
                <img
                  className="footer-content__link_credits"
                  src={macpawWordmark}
                  width="61"
                  height="12"
                  alt="Macpaw wordmark"
                />
              </a>
              <SupportLink className="footer-content__link" />
              <a className="footer-content__link" href={urls.termsOfUse} rel="noopener noreferrer">
                <FormattedMessage id="footer.termsLink" defaultMessage="Terms of Use" />
              </a>
              <a className="footer-content__link" href={urls.privacy} rel="noopener noreferrer">
                <FormattedMessage id="footer.policyLink" defaultMessage="Privacy Policy" />
              </a>
            </div>
          </Col>
          <Col sm={4}>
            <div className="footer__social">
              <FooterSocial />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </footer>
);

export default Footer;
