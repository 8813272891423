import React from 'react';
import type { ComponentType } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

// should be imported first so that basic styles imported first
// eslint-disable-next-line import/order
import AppRoot from './root';

import AppRoute from 'components/shared/routing/app-route';
import RedirectWithQuery from 'components/shared/routing/redirect-with-query';

import AppLayout from 'components/layout/app-layout/app-layout';
import OuterLayout from 'components/layout/outer-layout/outer-layout';

import SignUpPage from 'components/pages/signup/signup-page';
import FamilySignUpPage from 'components/pages/signup/family-signup/family-signup';
import ReferralSignUpPage from 'components/pages/signup/referral-signup/referral-signup';
import RedeemPage from 'components/pages/redeem/redeem';

import SignupPaymentDetails from 'components/pages/signup-payment-details/signup-payment-details';
import SignupConfirmEmail from 'components/pages/signup-confirm-email/signup-confirm-email';
import LoginPage from 'components/pages/login/login-container';
import SocialAuthPage from 'components/pages/social-auth/social-auth-page';
import OAuthLoginPage from 'components/pages/oauth-login/oauth-login-page';
import OAuthAuthorizationPage from 'components/pages/oauth-authorization/oauth-authorization-page';
import OAuthConfirmEmail from 'components/pages/oauth-confirm-email/oauth-confirm-email';
import SocialAuthWelcomePage from 'components/pages/social-auth-welcome-page/social-auth-welcome-page';
import NewPasswordPage from 'components/pages/new-password/new-password-container';
import RecoverPasswordPage from 'components/pages/recover-password/recover-password-page';
import SuccessfulRegistrationPage from 'components/pages/successful-registration/successful-registration-page';
import SuccessfulRegistrationSingleApp
  from 'components/pages/successful-registration-single-app/successful-registration-single-app';
import SuccessfulTrialActivationPage
  from 'components/pages/successful-trial-activation-page/successful-trial-activation-page';
import SuccessfulAiOfferActivation
  from 'components/pages/successful-ai-offer-activation/successful-ai-offer-activation';
import AccountDeletedPage from 'components/pages/account-deleted/account-deleted-page';

import ActivateSubscriptionPage from 'components/pages/activate-subscription/activate-subscription-page';
import CancelSubscription from 'components/pages/cancel-subscription/cancel-subscription-page';
import SuccessfulCancellationPage from 'components/pages/successful-cancellation/successful-cancellation-page';
import ChangePricePlanPage from 'components/pages/change-price-plan-page/change-price-plan-page';
import DeleteAccountPage from 'components/pages/delete-account/delete-account-page';
import AiOfferPage from 'components/pages/ai-offer/ai-offer-page';

import NotFoundPage from 'components/pages/not-found/not-found';

import AccountPage from 'components/pages/account-settings/account-page';
import ReferralPage from 'components/pages/referral/referral-page';
import PaymentHistoryPage from 'components/pages/payment-history/payment-history-page';
import DevicesPage from 'components/pages/devices/devices-page';
import IosAppsPage from 'components/pages/ios-apps/ios-apps-page';
import IosCollectionPage from 'components/pages/ios-collection-page/ios-collection-page';
import SubscriptionPage from 'components/pages/subscription/subscription-page';
import GiftCardsPage from 'components/pages/gift-cards/gift-cards-page';
import FamilyPlanPage from 'components/pages/family-plan-page/family-plan-page';
import AuthDesktopPage from 'components/pages/auth-desktop-page/auth-desktop-page';
import ActivateIosAppPage from 'components/pages/activate-ios-page/activate-ios-page';
import InvalidFamilyInvitePage from 'components/pages/invalid-family-invite/invalid-family-invite-page';
import RestrictSetappMobilePage from 'components/pages/restrict-setapp-mobile/restrict-setapp-mobile-page';
import FamilyInvitePage from 'components/pages/family-invite/family-invite-page';
import CustomerOauthPage from 'components/pages/customer-oauth-page/customer-oauth-page';
import AppAccessIssue from 'components/pages/app-access-issue/app-access-issue';
import AppAccessGranted from 'components/pages/app-access-granted/app-access-granted';
import SetappMobilePage from 'components/pages/setapp-mobile/setapp-mobile-page';
import HiddenInstallationPage from 'components/pages/setapp-mobile/hidden-installation-page';

import ActivateFamilyFlow from 'components/user-flow/activate/activate-family-flow/activate-family-flow';
import PaymentRetryFlow from 'components/user-flow/payment-retry/payment-retry-flow/payment-retry-flow';

import urls from './config/urls';

const AppRouter = () => (
  <Router basename={process.env.BASE_PATH || ''}>
    <Route
      render={(props) => (
        <AppRoot {...props}>
          <Switch>
            <AppRoute authenticated path={urls.account} layout={AppLayout} component={AccountPage} />
            <AppRoute authenticated path={urls.referral} layout={AppLayout} component={ReferralPage} />
            <AppRoute authenticated path={urls.devices} layout={AppLayout} component={DevicesPage} />
            <AppRoute authenticated path={urls.paymentHistory} layout={AppLayout} component={PaymentHistoryPage} />
            <AppRoute authenticated path={urls.paymentRetry} component={PaymentRetryFlow} />
            <AppRoute
              authenticated
              path={urls.subscription}
              layout={AppLayout}
              component={SubscriptionPage as ComponentType<RouteComponentProps>}
            />
            <AppRoute authenticated path={urls.giftCards} layout={AppLayout} component={GiftCardsPage} />
            <AppRoute authenticated path={urls.family} layout={AppLayout} component={FamilyPlanPage} />
            <AppRoute
              authenticated
              path={urls.signupAddPaymentMethod}
              component={SignupPaymentDetails}
            />
            <AppRoute
              authenticated
              path={urls.signupConfirmEmail}
              layout={OuterLayout}
              component={SignupConfirmEmail}
            />
            <AppRoute
              authenticated
              path={urls.successfulRegistration}
              layout={OuterLayout}
              component={SuccessfulRegistrationPage}
            />
            <AppRoute
              authenticated
              path={urls.successfulTrialActivation}
              component={SuccessfulTrialActivationPage}
            />
            <AppRoute
              authenticated
              path={urls.successfulAiOfferActivation}
              component={SuccessfulAiOfferActivation}
            />
            <AppRoute
              authenticated
              path={urls.activateFamilySubscription}
              component={ActivateFamilyFlow}
            />
            <AppRoute
              authenticated
              path={urls.activateSubscription}
              component={ActivateSubscriptionPage}
            />
            <AppRoute
              authenticated
              path={urls.changePricePlan}
              component={ChangePricePlanPage as ComponentType<RouteComponentProps>}
            />
            <AppRoute
              authenticated
              path={urls.successfulRegistrationSingleApp}
              component={SuccessfulRegistrationSingleApp}
            />
            <AppRoute
              authenticated
              path={urls.cancelSubscription}
              component={CancelSubscription}
            />
            <AppRoute
              authenticated
              path={urls.successfulCancellation}
              component={SuccessfulCancellationPage}
            />
            <AppRoute
              authenticated
              path={urls.deleteAccount}
              component={DeleteAccountPage}
            />
            <AppRoute
              authenticated
              path={urls.socialAuthWelcome}
              layout={OuterLayout}
              component={SocialAuthWelcomePage}
            />
            <AppRoute authenticated path={urls.authDesktop} layout={OuterLayout} component={AuthDesktopPage} />
            <AppRoute authenticated path={urls.customerOauth} component={CustomerOauthPage} />
            <AppRoute authenticated path={urls.appAccessIssue} component={AppAccessIssue} />
            <AppRoute authenticated path={urls.appAccessGranted} layout={OuterLayout} component={AppAccessGranted} />
            <AppRoute authenticated path={urls.acceptFamilyInvite} component={FamilyInvitePage} />
            <AppRoute
              path={urls.oauthAuthorize}
              component={OAuthAuthorizationPage}
            />
            <AppRoute path={urls.oauthConfirmEmail} component={OAuthConfirmEmail} />
            <AppRoute path={urls.oauthLogin} component={OAuthLoginPage} />
            <AppRoute authenticated path={`${urls.iosAppActivation}/:id`} component={ActivateIosAppPage} />
            <AppRoute authenticated path={`${urls.iosCollection}/:id`} layout={AppLayout} component={IosCollectionPage} />
            <AppRoute authenticated path={urls.iosApps} layout={AppLayout} component={IosAppsPage} />
            <AppRoute authenticated path={urls.setappMobile} layout={AppLayout} component={SetappMobilePage} />
            <AppRoute
              authenticated
              layout={OuterLayout}
              path={urls.hiddenInstallation}
              component={HiddenInstallationPage}
            />
            <AppRoute
              path={urls.recoverPassword}
              layout={OuterLayout}
              component={RecoverPasswordPage as ComponentType<RouteComponentProps>}
            />
            <AppRoute authenticated path={urls.aiOffer} component={AiOfferPage} />
            <AppRoute path={`${urls.newPassword}/:token?`} layout={OuterLayout} component={NewPasswordPage} />
            <AppRoute path={urls.successfulAccountDelete} component={AccountDeletedPage} />
            <AppRoute path={`${urls.socialAuth}/:provider`} layout={OuterLayout} component={SocialAuthPage} />
            <AppRoute path={urls.signupFamily} component={FamilySignUpPage} />
            <AppRoute path={urls.signupReferral} component={ReferralSignUpPage} />
            <AppRoute path={urls.redeem} component={RedeemPage} />
            <AppRoute path={urls.login} layout={OuterLayout} component={LoginPage} />
            <AppRoute path={`${urls.signup}/:invitation?`} layout={OuterLayout} component={SignUpPage} />
            <AppRoute path={urls.familyInviteError} component={InvalidFamilyInvitePage} />
            <AppRoute path={urls.restrictSetappMobile} component={RestrictSetappMobilePage} />

            <RedirectWithQuery from={urls.addPaymentMethod} to={urls.subscription} />
            <RedirectWithQuery from={urls.paymentDetails} to={urls.subscription} />
            <RedirectWithQuery from={urls.dashboardFromTeams} to={urls.subscription} />
            <RedirectWithQuery from={urls.discovery} to={urls.root} />
            <RedirectWithQuery exact from={urls.root} to={urls.subscription} />

            <AppRoute path="*" layout={OuterLayout} component={NotFoundPage} />
          </Switch>
        </AppRoot>
      )}
    />
  </Router>
);

export default AppRouter;
