import React from 'react';
import { FormattedMessage } from 'react-intl';
import QrCodeImg from '../../images/qr-code.png';

import './desktop-qr-code.scss';

const DesktopQrCode = () => {
  return (
    <>
      <h1 className="desktop-qr-code__title">
        <FormattedMessage id="desktopQrCode.title" defaultMessage="Setapp Mobile" />
      </h1>
      <p className="desktop-qr-code__description mb-0 mb-5">
        <FormattedMessage
          id="desktopQrCode.description"
          defaultMessage="Power up your iPhone with dozens of curated apps. Install Setapp Mobile and discover your new favorites."
        />
      </p>
      <div className="desktop-qr-code__container">
        <div className="desktop-qr-code__requirements">
          <p className="desktop-qr-code__requirements-title">
            <FormattedMessage id="desktopQrCode.requirements.title" defaultMessage="Make sure that:" />
          </p>
          <ul className="desktop-qr-code__list">
            <li className="desktop-qr-code__list-item mb-3">
              <FormattedMessage
                id="desktopQrCode.requirements.euLocation"
                defaultMessage="You’re physically located in the EU*"
              />
            </li>
            <li className="desktop-qr-code__list-item mb-3">
              <FormattedMessage
                id="desktopQrCode.requirements.iosVersion"
                defaultMessage="Your iPhone runs iOS 17.4 or later"
              />
            </li>
            <li className="desktop-qr-code__list-item mb-3">
              <FormattedMessage
                id="desktopQrCode.requirements.appleIdCountry"
                defaultMessage="Your Apple ID country is one of the EU states"
              />
            </li>
            <li className="desktop-qr-code__list-item">
              <FormattedMessage
                id="desktopQrCode.requirements.safariBrowser"
                defaultMessage="You use Safari (download may not work in other browsers yet)"
              />
            </li>
          </ul>
          <p className="desktop-qr-code__countries mt-8 mb-0">
            <FormattedMessage
              id="desktopQrCode.countries"
              defaultMessage="*Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, and Sweden."
            />
          </p>
        </div>
        <div className="desktop-qr-code__code">
          <img className="mb-10" src={QrCodeImg} alt="https://my.setapp.com/setapp-mobile" width="172" height="172" />
          <p className="desktop-qr-code__code-title mb-0">
            <FormattedMessage id="desktopQrCode.code.title" defaultMessage="Scan this QR code with your iPhone to install Setapp Mobile" />
          </p>
        </div>
      </div>
    </>
  );
};

export default DesktopQrCode;
