import { Client, FeatureFlagLoader, SatuCookieHelper } from '@setapp/abn-tests-client';
import type { Evidence, FeatureFlag } from '@setapp/abn-tests-client';

import { apiURL } from 'config/api';
import { COOKIES_ROOT_DOMAIN } from 'config/app';
import featureFlagsConfig from 'config/feature-flags';
import type { FeatureFlagKeys } from 'config/feature-flags';

export type ResolvedFeatureFlags = { [key in FeatureFlagKeys]: FeatureFlag };

export type EvidenceParams = {
  isEnLocale: boolean;
  isMacTrial: boolean;
  isIosTrial: boolean;
  isMobile: boolean;
  isEduUser: boolean;
}

export class FeatureFlags {
  private readonly loader: FeatureFlagLoader;
  private readonly featureFlagsClient: Client;

  constructor() {
    SatuCookieHelper.init({ domain: COOKIES_ROOT_DOMAIN });

    this.loader = new FeatureFlagLoader({ endpoint: apiURL.featureFlags });
    this.featureFlagsClient = new Client(this.loader, []);
  }

  /**
   * Generates array of evidences. Change evidences logic only from here
   */
  private static buildEvidences(params: EvidenceParams): Evidence[] {
    const { isEnLocale, isMacTrial, isIosTrial, isMobile, isEduUser } = params;

    return [
      {
        name: 'isEnLocale',
        value: String(isEnLocale),
      },
      {
        name: 'isMacTrial',
        value: String(isMacTrial),
      },
      {
        name: 'isIosTrial',
        value: String(isIosTrial),
      },
      {
        name: 'isMobile',
        value: String(isMobile)
      },
      {
        name: 'isEduUser',
        value: String(isEduUser)
      },
    ];
  }

  /**
   * Return value of requested feature flag with split result tracking
   */
  private getFeatureFlag(key: string, defaultValue: unknown): FeatureFlag {
    const featureFlag = this.featureFlagsClient.findOrDefault({ key, defaultValue });

    this.trackSplitResult(featureFlag);

    return featureFlag;
  }

  /**
   * Return value of requested feature flag without split result tracking
   */
  private getFeatureFlagSilently(key: string, defaultValue: unknown): FeatureFlag {
    return this.featureFlagsClient.findOrDefault({ key, defaultValue });
  }

  /**
   * Initialize ABN Tests Client and fetch feature flags
   */
  public async init(evidenceParams?: EvidenceParams) {
    if (evidenceParams) {
      this.featureFlagsClient.reset({ evidences: FeatureFlags.buildEvidences(evidenceParams) });
    }

    await this.featureFlagsClient.warmup();
  }

  /**
   * Change satu ID or/and list of evidences (after user sign-in, location changes, etc.)
   */
  public async reset(evidenceParams: EvidenceParams) {
    this.featureFlagsClient.reset({ evidences: FeatureFlags.buildEvidences(evidenceParams) });
    await this.featureFlagsClient.warmup();
  }

  /**
   * Send split result to DataHub
   */
  public trackSplitResult(featureFlag: FeatureFlag): void {
    this.featureFlagsClient.trackSplitResult(featureFlag, this.loader.satuId);
  }

  /**
   * Resolve all feature flags that will be used in the application
   */
  public resolveFeatureFlags(): ResolvedFeatureFlags {
    return featureFlagsConfig.reduce((acc, item) => {
      const { key, defaultValue, trackSplitResult = false } = item;

      acc[key] = trackSplitResult
        ? this.getFeatureFlag(key, defaultValue)
        : this.getFeatureFlagSilently(key, defaultValue);

      return acc;
    }, {} as ResolvedFeatureFlags);
  }
}

const featureFlags = new FeatureFlags();

export default featureFlags;
