import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  fetchAllSubscriptions,
  activateSubscription,
  activateOldSuspendedSubscription,
  changePricePlan,
} from 'state/subscription/subscription-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchDevices } from 'state/devices/devices-reducer';
import {
  getAppsCount,
  getPrimarySubscription,
  getSubscriptions,
  getAvailablePricePlans,
  getAdditionalSeatPlan,
  isEduSubscription,
  isPaymentMethodCreated,
  isUserPlainFamilyMember,
  isFreeUser,
  isReferralUser,
  isSuspendedSubscription,
  isSuspendedSubscriptionWithOldPricePlan,
  getDevices,
  getDevicesList,
  getMacDevicesList,
  hasPriceFeatures,
  getFeaturedPricePlans,
  getUser,
} from 'state/root-reducer';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import DefaultError from 'components/shared/default-error/default-error';
import SubscriptionActivated from 'components/shared/subscription-activated/subscription-activated';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import ActivateEduUserFlow from 'components/user-flow/activate/activate-edu-user-flow/activate-edu-user-flow';
import ActivateNewUserFlow from 'components/user-flow/activate/activate-new-user-flow/activate-new-user-flow';
import ActivateOldSuspendedUserFlow from 'components/user-flow/activate/activate-old-suspended-user-flow/activate-old-suspended-user-flow';
import ActivateSuspendedUserFlow from 'components/user-flow/activate/activate-suspended-user-flow/activate-suspended-user-flow';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
  appsCount: getAppsCount(),
  isSubscriptionProcessing: getSubscriptions(state).isLoading,
  pricePlans: getAvailablePricePlans(state),
  isEduSubscription: isEduSubscription(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isFreeUser: isFreeUser(state),
  isReferralUser: isReferralUser(state),
  isUserFamilyMember: isUserPlainFamilyMember(state),
  isSuspendedSubscription: isSuspendedSubscription(state),
  isSuspendedSubscriptionWithOldPricePlan: isSuspendedSubscriptionWithOldPricePlan(state),
  additionalSeatPricePlan: getAdditionalSeatPlan(state),
  activeDevicesCount: getDevicesList(state).length,
  activeMacDevicesCount: getMacDevicesList(state).length,
  isDevicesInfoLoading: getDevices(state).isLoading,
  hasPriceFeatures: hasPriceFeatures(state),
  featuredPricePlans: getFeaturedPricePlans(state),
  user: getUser(state),
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  fetchPaymentMethod,
  activateSubscription,
  activateOldSuspendedSubscription,
  showDangerNotification,
  fetchPricePlans,
  fetchDevices,
  changePricePlan,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history: RouteComponentProps['history'];
};

type ActivationFlow =
  'default'
  | 'suspendedUser'
  | 'oldSuspendedUser'
  | 'eduUser'
  | null;

type State = {
  activationFlow: ActivationFlow;
};

class ActivateSubscriptionPage extends Component<Props, State> {
  state: State = {
    activationFlow: null,
  };

  componentDidMount() {
    const {
      fetchAllSubscriptions,
      fetchDevices,
      fetchPaymentMethod,
      fetchPricePlans,
      showDangerNotification,
    } = this.props;

    return Promise.all([
      fetchAllSubscriptions(),
      fetchPaymentMethod(),
      fetchPricePlans(),
      fetchDevices(),
    ])
      .then(() => {
        const { isEduSubscription, isSuspendedSubscription, isSuspendedSubscriptionWithOldPricePlan } = this.props;

        let activationFlow: ActivationFlow = 'default';

        if (isSuspendedSubscriptionWithOldPricePlan) {
          activationFlow = 'oldSuspendedUser';
        } else if (isSuspendedSubscription) {
          activationFlow = 'suspendedUser';
        } else if (isEduSubscription) {
          activationFlow = 'eduUser';
        }

        this.setState({ activationFlow });
      })
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const {
      user,
      history,
      appsCount,
      primarySubscription,
      isSubscriptionProcessing,
      pricePlans,
      additionalSeatPricePlan,
      isDevicesInfoLoading,
      isPaymentMethodCreated,
      isFreeUser,
      isReferralUser,
      isUserFamilyMember,
      hasPriceFeatures,
      featuredPricePlans,
      activeDevicesCount,
      activeMacDevicesCount,
      activateOldSuspendedSubscription,
      showDangerNotification,
      fetchAllSubscriptions,
      activateSubscription,
      changePricePlan,
    } = this.props;

    const { activationFlow } = this.state;

    // Show success screen for free users and family members
    if (isFreeUser || isUserFamilyMember) {
      return <SubscriptionActivated />;
    }

    const isFeaturedPricePlansInfoLoading = hasPriceFeatures && !featuredPricePlans.length;
    const isPricePlansInfoLoading = !hasPriceFeatures
      && (!pricePlans.length || !additionalSeatPricePlan || isDevicesInfoLoading);

    if (
      !primarySubscription
      || isFeaturedPricePlansInfoLoading
      || isPricePlansInfoLoading
      || activationFlow === null
    ) {
      return <FullscreenLayoutLoading />;
    }

    // Activate old suspended user flow
    if (activationFlow === 'oldSuspendedUser') {
      return (
        <ActivateOldSuspendedUserFlow
          history={history}
          primarySubscription={primarySubscription}
          fetchAllSubscriptions={fetchAllSubscriptions}
          showDangerNotification={showDangerNotification}
          featuredPricePlans={featuredPricePlans}
          activateOldSuspendedSubscription={activateOldSuspendedSubscription}
        />
      );
    }

    // Activate suspended user flow
    if (activationFlow === 'suspendedUser') {
      return (
        <ActivateSuspendedUserFlow
          history={history}
          primarySubscription={primarySubscription}
          fetchAllSubscriptions={fetchAllSubscriptions}
          showDangerNotification={showDangerNotification}
          isPaymentMethodCreated={isPaymentMethodCreated}
          featuredPricePlans={featuredPricePlans}
          changePricePlan={changePricePlan}
        />
      );
    }

    // Activate edu user flow
    if (activationFlow === 'eduUser') {
      return (
        <ActivateEduUserFlow />
      );
    }

    // Activate new user flow (default)
    return (
      <ActivateNewUserFlow
        history={history}
        user={user}
        primarySubscription={primarySubscription}
        fetchAllSubscriptions={fetchAllSubscriptions}
        showDangerNotification={showDangerNotification}
        hasPriceFeatures={hasPriceFeatures}
        isPaymentMethodCreated={isPaymentMethodCreated}
        isReferralUser={isReferralUser}
        isSubscriptionProcessing={isSubscriptionProcessing}
        activeDevicesCount={activeDevicesCount}
        activeMacDevicesCount={activeMacDevicesCount}
        appsCount={appsCount}
        additionalSeatPricePlan={additionalSeatPricePlan}
        pricePlans={pricePlans}
        featuredPricePlans={featuredPricePlans}
        activateSubscription={activateSubscription}
        changePricePlan={changePricePlan}
      />
    );
  }
}

export { ActivateSubscriptionPage as PureActivateSubscriptionPage };

export default connector(ActivateSubscriptionPage);
