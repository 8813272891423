/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { v4 as uuidV4 } from 'uuid';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import type { Device } from 'state/devices/devices-initial-state';
import * as subscriptionsListService from 'services/subscriptions/subscriptions-list';
import * as subscriptionService from 'services/subscriptions/subscription';

/**
 * IMPORTANT: the seat id is generated each time the mapper function is called
 * It can cause issues in components that rely on stable seats' ids
 */
export type DeviceSeat = {
  id: string;
  subscription: Subscription;
  device: Device | null;
  isMainPlanSeat: boolean;
  isTrial: boolean;
};

const byExpirationDateDescending = (first: Device, second: Device) => {
  // handle the case when both dates are null and null < null is false as well as null > null
  if (first.expirationDate === second.expirationDate) {
    return 0;
  }

  return (first.expirationDate ?? 0) - (second.expirationDate ?? 0);
};

const byPendingPaymentAndStatus = (a: Subscription, b: Subscription) => {
  // seats with the pending payment come last
  if (a.paymentPending && !b.paymentPending) {
    return 1;
  }
  if (b.paymentPending && !a.paymentPending) {
    return -1;
  }

  // sort other by status ascending so we'll get active > cancelled > grace > blocked order
  return a.status - b.status;
};

export function mapDevicesToSeats(
  subscriptionsList: Array<Subscription>,
  devicesList: Array<Device>,
  hasPriceFeatures: boolean,
  userIosEuDeviceCount?: number,
): Array<DeviceSeat> {
  const primarySubscription = subscriptionsListService.getPrimarySubscription(subscriptionsList);

  if (!primarySubscription) {
    return [];
  }

  const additionalSeatsList = subscriptionsListService.getAdditionalSeats(subscriptionsList);
  const devices = devicesList.slice().sort(byExpirationDateDescending);
  const additionalSeats = additionalSeatsList.slice().sort(byPendingPaymentAndStatus);
  const primarySubscriptionSeatsCount = primarySubscription.pricePlan.seatsCount;
  const getTotalSeatsCount = () => {
    if (hasPriceFeatures && primarySubscription.pricePlan.features) {
      const macSeatsCount = primarySubscription.pricePlan.features.macSeatsCount || 0;
      const iosSeatsCount = primarySubscription.pricePlan.features.iosSeatsCount || 0;
      const iosEuSeatsCount = primarySubscription.pricePlan.features.iosEuSeatsCount || userIosEuDeviceCount || 0;

      return macSeatsCount + iosSeatsCount + iosEuSeatsCount;
    }

    return subscriptionsListService.getTotalSeatsCount(subscriptionsList);
  };
  const trialSeatsCount = subscriptionService.getTrialSeatsCount(primarySubscription);

  const devicesInSeat: Array<DeviceSeat> = [];

  for (let i = 0; i < getTotalSeatsCount(); i++) {
    const isMainPlanSeat = i < primarySubscriptionSeatsCount;
    const isActiveDeviceExist = devices.length && (
      !devices[0]!.expirationDate || devices[0]!.expirationDate > Date.now() / 1000
    );
    const isCancelledDeviceExist = devices.length && (
      devices[0]!.expirationDate && devices[0]!.expirationDate <= Date.now() / 1000
    );
    const isActiveSeatExist = additionalSeats.length && !subscriptionService.isStatusCancelled(additionalSeats[0]!);
    const shouldRenderEveryRemainingDevice = devices.length === (getTotalSeatsCount() - i);
    const isTrial = i >= getTotalSeatsCount() - trialSeatsCount;

    const createDeviceSeat = (subscription: Subscription, device: Device | null = null): DeviceSeat => ({
      id: uuidV4(),
      subscription,
      device,
      isMainPlanSeat,
      isTrial,
    });

    let deviceSeat: DeviceSeat;

    if (!hasPriceFeatures) {
      if (isMainPlanSeat) {
        if (isActiveDeviceExist || shouldRenderEveryRemainingDevice) {
          // primary subscription seat with a device
          deviceSeat = createDeviceSeat(primarySubscription, devices.shift());
        } else {
          // empty primary subscription seat
          deviceSeat = createDeviceSeat(primarySubscription);
        }
      } else if (isActiveDeviceExist) {
        // additional subscription seat with a device
        deviceSeat = createDeviceSeat(additionalSeats.shift()!, devices.shift());
      } else if (isActiveSeatExist && !shouldRenderEveryRemainingDevice) {
        // empty additional subscription seat
        deviceSeat = createDeviceSeat(additionalSeats.shift()!);
      } else if (isCancelledDeviceExist) {
        // cancelled additional subscription with a device
        const device = devices.shift()!;

        const inactiveSeatIndex = additionalSeats.findIndex((seat) => seat.expirationDate === device.expirationDate);
        const [seatSubscription] = additionalSeats.splice(inactiveSeatIndex, 1);

        deviceSeat = createDeviceSeat(seatSubscription!, device);
      } else {
        // empty cancelled additional subscription seat
        deviceSeat = createDeviceSeat(additionalSeats.shift()!);
      }
    } else if (isActiveDeviceExist) {
      deviceSeat = createDeviceSeat(primarySubscription, devices.shift());
    } else {
      deviceSeat = createDeviceSeat(primarySubscription);
    }

    devicesInSeat.push(deviceSeat);
  }

  return devicesInSeat;
}

export const getSubscriptions = (seats: Array<DeviceSeat>): Array<Subscription> => (
  seats.reduce((subscriptions, seat) => {
    // Several seats can have the same subscription so we avoid adding the same subscription twice
    if (subscriptions.includes(seat.subscription)) {
      return subscriptions;
    }

    return [
      ...subscriptions,
      seat.subscription,
    ];
  }, [] as Array<Subscription>)
);
