import request from 'utils/request';
import { apiURL } from 'config/api';
import type { Dispatch } from 'state/state-types';
import * as actionTypes from './price-plans-action-types';
import type { PricePlan } from './price-plans-initial-state';


// It's OK for actions to export one named function
// eslint-disable-next-line import/prefer-default-export
export function fetchPricePlans() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.pricePlans)
      .then((data: Array<PricePlan>) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}
