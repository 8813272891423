import React from 'react';
import { useSelector } from 'react-redux';

import {
  hasPriceFeatures as hasPriceFeaturesSelector,
  isUserFamilyOwner as isUserFamilyOwnerSelector,
} from 'state/root-reducer';

import OldFamilyPlanPage from 'components/pages/old-family-plan/old-family-plan-page';
import TryFamilyPlanPage from 'components/pages/try-family-plan-page/try-family-plan-page';
import ManageFamilyPage from 'components/pages/manage-family-page/manage-family-page';

const FamilyPlanPage = () => {
  const hasPriceFeatures = useSelector(hasPriceFeaturesSelector);
  const isUserFamilyOwner = useSelector(isUserFamilyOwnerSelector);

  if (hasPriceFeatures && isUserFamilyOwner) {
    return <ManageFamilyPage />;
  }

  return hasPriceFeatures ? <TryFamilyPlanPage /> : <OldFamilyPlanPage />;
};

export default FamilyPlanPage;
