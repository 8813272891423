import { PricePlan } from 'state/price-plans/price-plans-initial-state';

// Old price plans
export const MONTHLY = 'subscription_monthly';
export const ANNUAL = 'subscription_annual';
export const EDU = 'subscription_edu';
export const ADDITIONAL_SEAT_MONTHLY = 'additional_seat_monthly';
export const CMM_UPSALE = 'subscription_cmm_discount';
export const CYBER_MONDAY = 'subscription_cyber_monday';
export const OLD_FAMILY_MONTHLY = 'subscription_monthly_family';
export const OLD_FAMILY_ANNUAL = 'subscription_annual_family';

export const NEW_MONTHLY_1_MAC = 'test_regular_1_mac_monthly';
export const NEW_MONTHLY_1_MAC_UNLIM_IOS = 'test_regular_1_mac_unlimited_ios_monthly';
export const NEW_MONTHLY_4_MAC_UNLIM_IOS = 'test_regular_4_mac_unlimited_ios_monthly';
export const NEW_ANNUAL_1_MAC = 'test_regular_1_mac_annual';
export const NEW_ANNUAL_1_MAC_UNLIM_IOS = 'test_regular_1_mac_unlimited_ios_annual';
export const NEW_ANNUAL_4_MAC_UNLIM_IOS = 'test_regular_4_mac_unlimited_ios_annual';

// Featured price plans
export const MAC_TRIAL = 'regular:mac:trial';
export const MAC_PLUS_IOS_TRIAL = 'regular:mac_plus_ios:trial';
export const POWER_USER_TRIAL = 'regular:power_user_trial:trial';
export const SETAPP_FOR_BACKEND_TRIAL = 'regular:setapp_for_backend:trial';
export const EDU_TRIAL = 'regular:edu:trial';
export const IOS_TRIAL = 'regular:ios:trial';
export const IOS_ADVANCED_TRIAL = 'regular:ios_advanced:trial';
export const AI_ENTHUSIAST_TRIAL = 'regular:enthusiast:trial';
export const AI_EXPERT_TRIAL = 'regular:expert:trial';

export const MAC_MONTHLY = 'regular:mac:monthly';
export const MAC_PLUS_IOS_MONTHLY = 'regular:mac_plus_ios:monthly';
export const TWO_MACS_MONTHLY = 'regular:two_mac:monthly';
export const POWER_USER_MONTHLY = 'regular:power_user:monthly';
export const IOS_MONTHLY = 'regular:ios:monthly';
export const IOS_ADVANCED_MONTHLY = 'regular:ios_advanced:monthly';
export const SETAPP_FOR_BACKEND_MONTHLY = 'regular:setapp_for_backend:monthly';
export const FAMILY_MONTHLY = 'regular:family:monthly';
export const AI_ENTHUSIAST_MONTHLY = 'regular:enthusiast:monthly';
export const AI_EXPERT_MONTHLY = 'regular:expert:monthly';

export const MAC_ANNUAL = 'regular:mac:annual';
export const MAC_PLUS_IOS_ANNUAL = 'regular:mac_plus_ios:annual';
export const TWO_MACS_ANNUAL = 'regular:two_mac:annual';
export const POWER_USER_ANNUAL = 'regular:power_user:annual';
export const IOS_ANNUAL = 'regular:ios:annual';
export const IOS_ADVANCED_ANNUAL = 'regular:ios_advanced:annual';
export const EDU_ANNUAL = 'regular:edu:annual';
export const FAMILY_ANNUAL = 'regular:family:annual';
export const AI_ENTHUSIAST_ANNUAL = 'regular:enthusiast:annual';
export const AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_PREV = 'regular:enthusiast:annual:discounted_to_prev';
export const AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_FULL = 'regular:enthusiast:annual:discounted_to_full';
export const AI_EXPERT_ANNUAL = 'regular:expert:annual';
export const AI_EXPERT_ANNUAL_DISCOUNT_TO_PREV = 'regular:expert:annual:discounted_to_prev';
export const AI_EXPERT_ANNUAL_DISCOUNT_TO_FULL = 'regular:expert:annual:discounted_to_full';

export const CUSTOM = 'custom';

export type ChangePlanStrategy = 'charge' | 'scheduled' | 'instant';

export type ChangeSubscriptionPlan = PricePlan & {
  invoiceId?: string;
  strategy?: ChangePlanStrategy;
};

type TierTypeGroup = 'regular';
type TierType = 'mac'
  | 'mac_plus_ios'
  | 'two_mac'
  | 'power_user'
  | 'power_user_trial'
  | 'setapp_for_backend'
  | 'edu'
  | 'ios'
  | 'family';
type TierTypeBillingPeriod = 'monthly' | 'annual';

// FIXME: Think how to strict data types. Can we remove union with the `string` type?
// Tier type data
export type TierTypeData = Readonly<{
  group?: TierTypeGroup | string;
  type?: TierType | string;
  billingPeriod?: TierTypeBillingPeriod | string;
}>;

// Price key data
export const PricePlanCustomName = {
  loyalUser: 'loyal_user',
} as const;

export type PriceKeyData = TierTypeData & Readonly<{
  customName?: string;
}>;
