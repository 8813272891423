import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';
import classNames from 'classnames';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { UserAICredits } from 'state/user/user-initial-state';

import PricePerMonth from 'components/shared/price-per-month/price-per-month';
import ErrorMessage from 'components/shared/error-message/error-message';
import InfoButton from 'components/shared/info-button/info-button';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import successIcon from './images/success-icon.svg';

import './ai-offer-plan-card.scss';

type Props = {
  pricePlan: PricePlan;
  title: ReactNode;
  description: ReactNode;
  buttonTitle: ReactNode;
  aiCreditsLimit: number;
  aiMessagesExample: UserAICredits['example'];
  onSelectPlanClick: (pricePlan: PricePlan) => void;
  isCurrent?: boolean;
  isLoading?: boolean;
  isPromoted?: boolean;
  errorMessage?: ReactNode;
  priceWithoutDiscount?: number;
};

const AiOfferPlanCard = (props: Props) => {
  const {
    isCurrent = false,
    isLoading = false,
    isPromoted = false,
    pricePlan,
    title,
    description,
    buttonTitle,
    errorMessage,
    priceWithoutDiscount,
    aiCreditsLimit,
    aiMessagesExample,
    onSelectPlanClick,
  } = props;

  const cardClasses = classNames('ai-offer-card', {
    'ai-offer-card_current': isCurrent,
    'ai-offer-card_promoted': isPromoted,
  });

  const aiMessages = {
    ...aiMessagesExample,
    gpt4o: aiMessagesExample.gpt4Turbo * 2,
  };

  if (!pricePlan.features) {
    return null;
  }

  return (
    <div className="ai-offer-card__wrapper">
      <div className={cardClasses}>
        <div className="ai-offer-card__header">
          <div className="h4 mt-0 mb-0">{title}</div>
          {isCurrent && (
            <img src={successIcon} className="mt-1" alt="Current plan icon" />
          )}
          {isPromoted && (
            <div className="ai-offer-card__best-label">
              <FormattedMessage
                id="aiOfferPlan.bestValue"
                defaultMessage="best value"
              />
            </div>
          )}
        </div>
        <div className="ai-offer-card__body text_lg">
          <div className="h3 mb-5">
            {priceWithoutDiscount && (
              <span className="ai-offer-card__discount">
                <FormattedPrice
                  currency={pricePlan.currency}
                  price={priceWithoutDiscount}
                />
              </span>
            )}
            <PricePerMonth pricePlan={pricePlan} />
          </div>
          <p className="mb-4">{description}</p>

          <div className="ai-offer-card__credits">
            <FormattedMessage
              id="aiOfferPlan.credits"
              defaultMessage="{creditsLimit} credits per month"
              values={{ creditsLimit: aiCreditsLimit }}
            />

            <InfoButton>
              <div className="ai-credits-field-value__info-content">
                <FormattedMessage
                  id="aiOfferPlan.aiCreditsContent"
                  defaultMessage="Approximately {gpt35} messages with GPT-3.5 Turbo, or {gpt4o} messages with GPT-4o, or {whisper} minutes with Whisper."
                  values={aiMessages}
                />
              </div>
            </InfoButton>
          </div>

          <ButtonLoading
            size="lg"
            disabled={isCurrent}
            isLoading={isLoading}
            variant={isCurrent ? 'primary-outline' : 'primary'}
            block
            onClick={() => onSelectPlanClick(pricePlan)}
          >
            {buttonTitle}
          </ButtonLoading>
          <ul className="ai-offer-card__list">
            {Boolean(pricePlan.features.macSeatsCount) && (
              <li className="ai-offer-card__list-item mb-2">
                <FormattedMessage
                  id="aiOfferPlan.macNumbers"
                  defaultMessage="For {macNumber} {macNumber, plural, one {Mac} other {Macs}}"
                  values={{ macNumber: pricePlan.features.macSeatsCount }}
                />
              </li>
            )}
            {Boolean(pricePlan.features.iosSeatsCount) && (
              <li className="ai-offer-card__list-item mb-2">
                <FormattedMessage
                  id="aiOfferPlan.iosNumbers"
                  defaultMessage="For {iosNumber} iOS {iosNumber, plural, one {device} other {devices}}"
                  values={{ iosNumber: pricePlan.features.iosSeatsCount }}
                />
              </li>
            )}
          </ul>
        </div>
        {errorMessage && (
          <ErrorMessage className="mt-4">{errorMessage}</ErrorMessage>
        )}
      </div>
    </div>
  );
};

export default AiOfferPlanCard;
