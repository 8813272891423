import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import urls from 'config/urls';

import { events } from 'utils/analytics';

import MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER from 'services/manage-subscription/manage-subscription-preselected-step-mapper';
import { PricePlanPeriod } from 'services/price-plans/types';

import * as subscriptionStatuses from 'state/subscription/statuses';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import SwitchPlanNotification from '../switch-plan-notification/switch-plan-notification';

type Props = {
  hasPriceFeatures: boolean;
  primarySubscription: Subscription;
  onHide: () => void;
};

const SwitchToAnnualNotification = ({ hasPriceFeatures, primarySubscription, onHide }: Props) => {
  const url = hasPriceFeatures && primarySubscription.status === subscriptionStatuses.TRIAL
    ? `${urls.changePricePlan}?billing_period=${PricePlanPeriod.Annual}`
    : `${urls.subscription}?show_change_plan_modal=${MANAGE_SUBSCRIPTION_PRESELECTED_STEP_MAPPER.ManageBillingPeriodAnnual}`;

  return (
    <SwitchPlanNotification
      onHide={onHide}
      submitEventName={events.SWITCH_TO_ANNUAL_LINK_CLICK}
      buttonLink={url}
    >
      <FormattedHTMLMessage
        id="switchPlanNotification.toAnnual.text"
        defaultMessage="<strong>Save 10%</strong> on subscription with the annual plan. Switch to get a discount."
      />
    </SwitchPlanNotification>
  );
};

export default SwitchToAnnualNotification;
