import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormSwitcher, AnimatedLogo } from '@setapp/ui-kit';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import urls from 'config/urls';
import logger from 'utils/logger';

import type { Subscription } from 'state/subscription/subscription-initial-state';
import { FAMILY_ANNUAL, FAMILY_MONTHLY } from 'state/price-plans/price-plans-types';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { getFamilyPricePlans, isPaymentDetailsLoading } from 'state/root-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { changeTrialPricePlan } from 'state/subscription/subscription-actions';

import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';
import PaymentFormTotal from 'components/shared/payment-form-total/payment-form-total';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { FormattedPrice } from 'components/shared/formatter/formatter';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import DefaultError from 'components/shared/default-error/default-error';

const mapStateToProps = (state) => ({
  familyPricePlans: getFamilyPricePlans(state),
  isPaymentDetailsLoading: isPaymentDetailsLoading(state),
});

const mapActionsToProps = {
  fetchPricePlans,
  changeTrialPricePlan,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

interface PureProps {
  mainSubscription: Subscription;
}

type Props = RouteComponentProps & ConnectedProps<typeof connector> & PureProps;

type State = {
  switchedToAnnual: boolean;
  isLoading: boolean;
}

class SignupFamilyFlow extends PureComponent<Props, State> {
  state: State = {
    switchedToAnnual: false,
    isLoading: false,
  };

  componentDidMount() {
    const { fetchPricePlans, mainSubscription } = this.props;

    const dataToBeFetched = [
      fetchPricePlans(),
    ];

    if (mainSubscription.nextPricePlan && mainSubscription.nextPricePlan.tierType === FAMILY_ANNUAL) {
      this.setState({ switchedToAnnual: true });
    }

    return Promise.all(dataToBeFetched)
      .catch((error) => {
        logger.logWarn('Could not load data for price plans', error);
      });
  }

  componentDidUpdate(prevProps: Props) {
    const { mainSubscription } = this.props;

    if (mainSubscription.nextPricePlan && prevProps.mainSubscription.nextPricePlan && (
      mainSubscription.nextPricePlan.tierType !== prevProps.mainSubscription.nextPricePlan.tierType
    )) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { familyPricePlans, mainSubscription, isPaymentDetailsLoading } = this.props;
    const { switchedToAnnual, isLoading } = this.state;

    if (!familyPricePlans.length) {
      return <FullscreenLayoutLoading />;
    }

    const familyMonthlyPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_MONTHLY);
    const familyAnnualPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_ANNUAL);
    const selectedPricePlan = switchedToAnnual ? familyAnnualPricePlan : familyMonthlyPricePlan;

    // This check only for TS
    if (!selectedPricePlan) {
      return null;
    }

    if (!mainSubscription.nextPricePlan) {
      return null;
    }

    return (
      <div className="signup-payment-details-form__container">
        <div className="signup-payment-details-form">
          <h3 className="mb-6">
            <FormattedMessage
              id="signup.family.paymentForm.title"
              defaultMessage="Set up payment"
            />
          </h3>
          <div className="text_weight-bold text_lg">
            <PricePlanTitle tierType={selectedPricePlan.tierType} />
          </div>
          <div className="signup-payment-details-description__delimiter" />
          <FormSwitcher
            className="mb-5"
            id="annual-plan-switcher"
            disabled={isLoading || isPaymentDetailsLoading}
            checked={switchedToAnnual}
            onChange={this.onChangePlanPeriod}
          >
            <FormattedMessage
              id="signup.family.paymentForm.annualDiscount"
              defaultMessage="Save 10% annually"
            />
          </FormSwitcher>
          <div className="mb-6">
            <PaymentFormTotal
              totalPrice={<FormattedPrice currency={selectedPricePlan.currency} price={selectedPricePlan.price} />}
            />
          </div>
          <div className="mb-5">
            {isLoading ? (
              <div className="text-center mt-10 mb-10">
                <AnimatedLogo animate />
              </div>
            ) : (
              <PaymentForm
                onSuccessSubmit={this.handlePaymentFormSubmit}
                braintreeFormOptions={{
                  submitBtnTitle: (
                    <FormattedMessage
                      id="signup.family.paymentForm.payButtonTitle"
                      defaultMessage="Pay now"
                    />
                  ),
                  loadingText: (
                    <FormattedMessage
                      id="signup.family.paymentForm.payButtonLoadingText"
                      defaultMessage="Payment in progress…"
                    />
                  ),
                }}
                paymentMethodOptions={{
                  amount: selectedPricePlan.price,
                  currencyCode: selectedPricePlan.currency,
                }}
              />
            )}
          </div>
          <NextPaymentDateEstimation
            price={selectedPricePlan.price}
            currency={selectedPricePlan.currency}
            paidMonth={selectedPricePlan.paidMonth}
          />
          <div className="mt-2">
            <TaxInfo />
          </div>
        </div>
      </div>
    );
  }

  onChangePlanPeriod = () => {
    const { switchedToAnnual } = this.state;
    const { changeTrialPricePlan, showDangerNotification, familyPricePlans } = this.props;

    const familyMonthlyPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_MONTHLY);
    const familyAnnualPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_ANNUAL);
    const nextPricePlan = switchedToAnnual ? familyMonthlyPricePlan : familyAnnualPricePlan;

    this.setState({ isLoading: true });

    if (!nextPricePlan) {
      showDangerNotification(<DefaultError />);

      return;
    }

    changeTrialPricePlan(nextPricePlan.priceKey)
      .then(() => {
        this.setState({ switchedToAnnual: !switchedToAnnual });
      }).catch(() => {
        showDangerNotification(<DefaultError />);
        this.setState({ isLoading: false });
      });
  }

  handlePaymentFormSubmit = () => {
    const { history } = this.props;

    history.push(urls.successfulRegistration, { forceFamilyOwnerState: true });
  }
}

export { SignupFamilyFlow as PureSignupFamilyFlow };

export default connector(withRouter(SignupFamilyFlow));
