// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { PriceRadio } from '@setapp/ui-kit';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';

import { type PricePlan } from 'state/price-plans/price-plans-initial-state';

import PricePerMonth from 'components/shared/price-per-month/price-per-month';

import './billing-period-radio.scss';

type Props = {|
  isSelected: boolean,
  pricePlan: PricePlan,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
|}

const BillingPeriodRadio = (props: Props) => {
  const { isSelected, pricePlan, onChange } = props;

  const isMonthlyPlan = pricePlan.paidMonth === 1;

  const pricePlanMetadata = getPricePlanMetadata(pricePlan);
  const { titleFull: planTitle } = pricePlanMetadata;

  const descriptionClasses = classNames({
    'billing-period-radio__description_disabled': !isSelected,
  });
  const priceClasses = classNames({
    'billing-period-radio__price_disabled': !isSelected,
  });

  const title = isMonthlyPlan ? (
    <FormattedMessage
      id="changePricePlanFlow.changeBillingPeriodStep.monthlyOption.title"
      defaultMessage="Monthly"
    />
  ) : (
    <div className="billing-period-radio__title_promo">
      <FormattedMessage
        id="changePricePlanFlow.changeBillingPeriodStep.annualOption.title"
        defaultMessage="Annual"
      />
      <div className="billing-period-radio__promo">
        <FormattedMessage
          id="changePricePlanFlow.changeBillingPeriodStep.annualOption.title.promo"
          defaultMessage="Save 10%"
        />
      </div>
    </div>
  );
  const description = isMonthlyPlan ? (
    <FormattedMessage
      id="changePricePlanFlow.changeBillingPeriodStep.monthlyOption.description"
      defaultMessage="{planTitle}, billed monthly."
      values={{ planTitle }}
    />
  ) : (
    <FormattedMessage
      id="changePricePlanFlow.changeBillingPeriodStep.annualOption.description"
      defaultMessage="{planTitle}, billed annually."
      values={{ planTitle }}
    />
  );

  return (
    <PriceRadio
      value={pricePlan.tierType}
      checked={isSelected}
      title={title}
      description={<div className={descriptionClasses}>{description}</div>}
      price={(
        <span className={priceClasses}>
          <PricePerMonth pricePlan={pricePlan} />
        </span>
      )}
      onChange={onChange}
    />
  );
};

export default BillingPeriodRadio;
