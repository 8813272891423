import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { FormSwitcher } from '@setapp/ui-kit';

import urls from 'config/external-urls';

import { getPriceWithGracePeriod } from 'services/price-plans/utils';

import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { GracePeriod } from 'state/subscription/subscription-initial-state';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import InfoButton from 'components/shared/info-button/info-button';
import PaymentFormTotal from 'components/shared/payment-form-total/payment-form-total';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';

import './next-payment-info.scss';

type Props = {
  isProcessing?: boolean;
  switchedToAnnual?: boolean;
  showPricePlanPeriodSwitcher?: boolean;
  pricePlan: PricePlan;
  gracePeriod?: GracePeriod;
  onChangePlanPeriod?: () => void;
};

const NextPaymentInfo = (props: Props) => {
  const {
    isProcessing = false,
    switchedToAnnual = false,
    showPricePlanPeriodSwitcher = true,
    pricePlan,
    gracePeriod,
    onChangePlanPeriod,
  } = props;

  const { currency, price, tierType } = pricePlan;
  const hasGracePeriod = Boolean(gracePeriod);
  const totalAmount = getPriceWithGracePeriod(pricePlan, gracePeriod);

  return (
    <>
      <div className="next-payment-info__price-row text_lg">
        <div className="text_weight-bold">
          <PricePlanTitle tierType={tierType} />
        </div>
        {hasGracePeriod && (
          <FormattedPrice
            currency={currency}
            price={price}
          />
        )}
      </div>
      {hasGracePeriod && (
        <>
          <div
            className="next-payment-info__price-row mt-2 text_lg"
          >
            <div>
              <span className="text_weight-bold">
                <FormattedMessage
                  id="nextPaymentInfo.gracePeriod.title"
                  defaultMessage="Grace Period"
                />
              </span>
              &nbsp;
              <InfoButton>
                <FormattedMessage
                  id="nextPaymentInfo.gracePeriod.hint"
                  defaultMessage="Your account entered the grace period on {graceStartDate}, because we couldn’t charge your card at the end of the billing cycle. Learn more about the grace period {gracePeriodDocsLink}."
                  values={{
                    gracePeriodDocsLink: (
                      <a
                        className="next-payment-info__grace-period-docs-link"
                        href={urls.gracePeriodDocs}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="nextPaymentInfo.gracePeriod.hint.gracePeriodDocsLink"
                          defaultMessage="here"
                        />
                      </a>
                    ),
                    graceStartDate: (
                      <strong>
                        <FormattedDate
                          value={gracePeriod!.startDate * 1000}
                          year="numeric"
                          month="short"
                          day="numeric"
                        />
                      </strong>
                    ),
                  }}
                />
              </InfoButton>
            </div>
            <FormattedPrice currency={gracePeriod!.debtCurrency} price={gracePeriod!.debtAmount} />
          </div>
        </>
      )}

      <hr className="next-payment-info__delimiter" />

      {showPricePlanPeriodSwitcher && (
        <FormSwitcher
          id="annual-plan-switcher"
          disabled={isProcessing}
          checked={switchedToAnnual}
          onChange={onChangePlanPeriod}
        >
          <FormattedMessage
            id="nextPaymentInfo.switchToAnnual"
            defaultMessage="Save 10% annually"
          />
        </FormSwitcher>
      )}

      <div className="mt-6">
        <PaymentFormTotal
          totalPrice={<FormattedPrice currency={currency} price={totalAmount} />}
        />
      </div>
    </>
  );
};

export default NextPaymentInfo;
